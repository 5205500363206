.about {
    background: #000;
    color: #eee;
    margin: 4px;
    font-family: "Open Sans", sans-serif;

    a, a:active, a:hover, a:visited {
        color: #eee;
    }
    .text {
        max-width: 1200px;
        margin: auto;
        margin-top: 4%;
        //height: calc(100% - 8%);
        //overflow: auto;
        padding-right: 16px;
        margin-bottom: 4%;
    }
}
body.about-page {
    overflow-y: auto;
}
