.second {
    cursor: none;
    pointer-events: none;

    .fallback, .title, .text {
        position: absolute;
        color: white;
        font-family: "Arial";
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        height: calc(100% - 48px);
        width: calc(100% - 48px);
        padding: 24px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-shadow: 4px 4px 2px black;
    }
    iframe.inline {
        width: 100%;
        height: 100%;
        border: 0;
    }
}
