.overview {
    background: #000;
    color: #eee;
    margin: 4px;
    font-family: "Open Sans", sans-serif;
    img {
        max-width: 320px;
        float: left;
        margin: 4px;
    }

    p {
        clear: left;
        opacity: 0.8;
    }
    p:hover {
        opacity: 1;
    }
    p.soon, p.soon:hover {
        opacity: 0.3;
    }

    #intro {
        max-width: 1200px;
        margin: auto;
        margin-top: 4%;
        height: 100%;
        overflow: auto;
        h1 {
            font-weight: normal;
        }
    }

    a, a:active, a:hover, a:visited {
        color: #ddd;
        text-decoration: none;
    }

    .nav {
        text-align: center;
        position: absolute;
        top: 8px;
        right: 16px;

        a:hover {
            color: #fff;
        }
    }
}
